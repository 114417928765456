import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './pages/login/Login.js';
import Register from './pages/register/Register.js';
import ProtectedRoute from './components/ProtectedRoute.js'
import HomePage from './pages/home/Home.js';
import Wiki from './pages/wiki/Wiki.js';

const App = () => {
    return (
      //<AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/wiki" element={<Wiki/>} />

                <Route
                  path="/main"
                  element={
                    // <ProtectedRoute>
                      <HomePage />
                    // </ProtectedRoute>
                  }
                />
                <Route path="/" exact element={<Login/>} />
            </Routes>
        </BrowserRouter>
        //</AuthProvider>
    );
};

export default App;