import React from "react";
import styles from "./LeftSidebar.module.css";
import logo from "../../assets/img/logotype-ex.png"
import gameImg from "../../assets/img/bg.png"
import userImg from "../../assets/svg/user-solid-rounded.svg"
import language from "../../assets/img/united-kingdom.png"
import shop from "../../assets/svg/shop.svg"
import wallet from "../../assets/svg/wallet-2.svg"
import chart from "../../assets/svg/chart-bar-trend-up.svg"
import nodes from "../../assets/svg/nodes.svg"
import mesgs from "../../assets/svg/msgs.svg"
import book from "../../assets/svg/book-bookmark.svg"
import headset from "../../assets/svg/headset.svg"

const LeftSidebar = () => {
  return (
    <div className={styles.left_sidebar}>
    
    <div className={styles.topBar}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
      </div>

      <img src={gameImg} alt="Logo"  className={styles.gameImg}/>

       <div className={styles.divider}></div> 
 
      
        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={userImg} alt="Icon 1" />
        </div>
        
        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={shop} alt="Icon 2" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={wallet} alt="Icon 1" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={chart} alt="Icon 1" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={nodes} alt="Icon 1" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={mesgs} alt="Icon 1" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={book} alt="Icon 1" />
        </div>

        <div className={styles.icon}>
            <link rel="icon" href="#" />
            <img src={headset} alt="Icon 1" />
        </div>

      </div>

      <div className={styles.language}>
        <img src={language} alt="Language" />
      </div>
    </div>
  );
};

export default LeftSidebar;
