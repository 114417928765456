import React, { useState } from 'react';
import styles from './TrophiesTable.module.css';
import photoTest from '../../assets/img/photo.png';

const trophies = [
  { id: 1, name: 'Shadowfang Blade', icon: photoTest, quantity: '5,098,021', chance: '50%' },
  { id: 2, name: 'Mystic Orb', icon: photoTest, quantity: '900,004', chance: '30%' },
  { id: 3, name: 'Crimson Talisman', icon: photoTest, quantity: '236,098', chance: '26%' },
  { id: 4, name: 'Phoenix Feather', icon: photoTest, quantity: '156,065', chance: '19%' },
  { id: 5, name: 'Ironclad Shield', icon: photoTest, quantity: '90,135', chance: '17%' },
  { id: 6, name: 'Runestone of Power', icon: photoTest, quantity: '1,900', chance: '8%' },
];

const TrophiesTable = () => {
  const [selectedId, setSelectedId] = useState(null);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Name</th>
          <th>QTY</th>
          <th>Chance</th>
        </tr>
      </thead>
      <tbody>
        {trophies.map((trophy) => (
          <tr
            key={trophy.id}
            className={trophy.id === selectedId ? styles.activeRow : ''}
            onClick={() => setSelectedId(trophy.id)}
          >
            <td>
              <div className={styles.row}>
                <img src={trophy.icon} alt={trophy.name} className={styles.icon} />
                <span>{trophy.name}</span>
              </div>
            </td>
            <td>{trophy.quantity}</td>
            <td>{trophy.chance}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TrophiesTable;
