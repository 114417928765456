import React, { useState } from "react";
import InputField from "./InputField";
import styles from "./RegistrationForm.module.css";
import imDeckor from "../../assets/img/decor.png";
import img from "../../assets/img/banner.png";
import imgLogo from "../../assets/img/logotype-ex.png";
import { Link } from "react-router-dom";
import userLogo from "../../assets/img/user-id-verification-solid-rounded.png";
import passwordLogo from "../../assets/img/lock-password-solid-rounded.png";
import userNameLogo from "../../assets/img/user-solid-rounded.png";
import emailLogo from "../../assets/img/mail-02-solid-rounded.png";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import footerLinksImg from "../../assets/img/headset.png";
import { styled } from '@mui/material/styles';
import languageImg from "../../assets/img/united-kingdom.png";
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  backgroundColor: '#69707e',

  '.Mui-focusVisible &': { 
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});


function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{ '&:hover': { bgcolor: 'transparent' } }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    userId: "",
    password: "",
    accountName: "",
    email: "",
    verificationCode: "",
  });

  const [errors, setErrors] = useState({});
  const [resendTimer, setResendTimer] = useState(60);

  const validate = () => {
    const newErrors = {};
    if (!formData.userId) newErrors.userId = "User ID is required.";
    if (formData.password.length < 8) newErrors.password = "Password must be at least 8 characters.";
    if (!formData.accountName) newErrors.accountName = "Account Name is required.";
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Invalid email address.";
    if (formData.verificationCode !== "1FW43P") newErrors.verificationCode = "Invalid verification code.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Form submitted successfully!");
    }
  };

  const handleResend = () => {
    if (resendTimer === 0) {
      setResendTimer(60);
      const interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev === 1) clearInterval(interval);
          return prev - 1;
        });
      }, 1000);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftImg}>
        <img src={imgLogo} className={styles.imgLogo} alt="background" />
        <img src={imDeckor} className={styles.imDeckor} alt="background" />
        <img src={img} className={styles.img} alt="Language" />
        <p className={styles.p}>Excellent Games Inc.</p>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.languageSelector}>
        <div>
          <img src={languageImg} alt="Language" />
        </div>
      </div>
        <h1>Create an account</h1>
        <p className={styles.account}>
          Already have an account?
          <Link to="/login">Sign in</Link>
        </p>
        <InputField
          label="User ID"
          name="userId"
          value={formData.userId}
          onChange={handleChange}
          error={errors.userId}
          icon={userLogo}
        />
        <InputField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={errors.password}
          icon={passwordLogo}
        />
        <p className={styles.inputHelpText}>8 characters minimun</p>
        <InputField
          label="Account Name"
          name="accountName"
          value={formData.accountName}
          onChange={handleChange}
          error={errors.accountName}
          icon={userNameLogo}
        />
        <p className={styles.inputHelpText}>Visible nickname for your profile</p>
        <InputField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email}
          icon={emailLogo}
        />
          <button
            type="button"
            className={styles.resendButton}
            onClick={handleResend}
            disabled={resendTimer > 0}
          >
            Resend {resendTimer > 0 ? `(${resendTimer}s)` : ""}
          </button>
        <InputField
          label="Verification Code"
          name="verificationCode"
          value={formData.verificationCode}
          onChange={handleChange}
          error={errors.verificationCode}
          icon={emailLogo}
        >
        </InputField>
        <div className={styles.checkboxes}>


        <FormGroup>
          <FormControlLabel control={<BpCheckbox />} label={<>[Required] I read the <a href="/terms">Terms of Service</a> and I agree to the terms.</>} />
          <FormControlLabel  control={<BpCheckbox />} label={<>[Required] I read the  <a href="/privacy">Privacy Policy</a> and I agree to the terms.</>} />
          <FormControlLabel  control={<BpCheckbox />} label={<>[Optional] I would like to receive promotional emails.</>} />
        </FormGroup>
{/* 
          <label>
            <input type="checkbox" required /> [Required] I read the <a href="/terms">Terms of Service</a> and I agree to the terms.
          </label>
          <label>
            <input type="checkbox" required /> [Required] I read the  <a href="/privacy">Privacy Policy</a> and I agree to the terms.
          </label>
          <label>
            <input type="checkbox" /> [Optional] I would like to receive promotional emails.
          </label> */}
        </div>
        <button type="submit" className={styles.submitButton}>
          Create Account
        </button>
        <div className={styles.footerLinks}>

          <div>
          <img className={styles.footerLinksImg} src={footerLinksImg}></img>
          <a href="/promo-code">Have Promo Code?</a>
          </div>

          <div><a href="/support">Contact Support</a></div>
          
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
