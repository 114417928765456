import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrophiesTable from './TrophiesTable';
import styles from './TrophiesAndLocation.module.css';

const TrophiesAndLocation = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={styles.container}>
      <Tabs value={activeTab} onChange={handleChange} textColor="inherit">
        <Tab label="Trophies" />
        <Tab label="Location" />
      </Tabs>
      {activeTab === 0 && <TrophiesTable />}
      {activeTab === 1 && <div>Location info will appear here.</div>}
    </div>
  );
};

export default TrophiesAndLocation;
