import React from "react";
import styles from "./Wiki.module.css"
import LeftSidebar from "./LeftSidebar.js";
import RightSidebar from "./RightSidebar.js";
import Header from "./Header.js";
import CharacterDetails from "./CharacterDetails.js";
import { Box } from '@mui/material';
import TrophiesAndLocation from './TrophiesAndLocation.js';


function Wiki() {
  return (
    <div className={styles.container}>
      <LeftSidebar />
      <RightSidebar />
      <div className={styles.mainWrapper}>
        <Header />
        <CharacterDetails />
        <TrophiesAndLocation />
      </div>
    </div>
  );
}

export default Wiki;