import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import imDeckor from "../../assets/img/decor.png";
import img from "../../assets/img/banner.png";
import imgLogo from "../../assets/svg/logotype-ex.svg";
import languageImg from "../../assets/svg/united-kingdom.svg";
import InputField from "../register/InputField"
import userLogo from "../../assets/svg/user-id-verification-solid-rounded.svg";
import passwordLogo from "../../assets/img/lock-password-solid-rounded.png";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  color: '#69707e',

  '.MuiFormControlLabel-label': {
    color: '#69707e',
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#009be8',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#69707e',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));


const LoginForm = () => {
  const [formData, setFormData] = useState({ emailOrId: "", password: "", rememberMe: false });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation
    if (!formData.emailOrId || !formData.password) {
      setError("Please check your password and account name and try again.");
      return;
    }
    setError("");
    alert("Logged in successfully!");
  };

  return (
    <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.leftImgWrp}>
        <img src={imgLogo} className={styles.imgLogo} alt="background" />
        <img src={imDeckor} className={styles.imDeckor} alt="background" />
        <img src={img} className={styles.img} alt="Language" />
        <p className={styles.p}>Excellent Games Inc.</p>
      </div>
      <div className={styles.formContainer}>
      <div className={styles.languageSelector}>
        <div>
          <img src={languageImg} alt="Language" />
        </div>
      </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h1>Welcome Back</h1>
          <p className={styles.accountLink}>
            Don't have an account? 
            <Link to="/register">Create now</Link>
          </p>
          <InputField
          label="ID or E-mail"
          name="userId"
          value={formData.userId}
          onChange={handleChange}
          error={false}
          icon={userLogo}
          placeholder="Enter User ID or E-mail"
        />
        <InputField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          error={false}
          icon={passwordLogo}
          placeholder="Enter Password"
        />
          {error && <div className={styles.error}>Please check your password and account name and try again.</div>}
          <div className={styles.options}>
            <FormControlLabel styles={styles.formControl} control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} label="Remember me" />
            <FormControlLabel required control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} label="IP Securty" />
          </div>
          <button type="submit" className={styles.submitButton}>
            Login
          </button>
          <div className={styles.links}>
            <a href="/forgot-id">Forgot ID</a>
            <span>•</span>
            <a href="/reset-password">Reset Password</a>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default LoginForm;
