import React from 'react';
import styles from './CharacterDetails.module.css';
import photoTest from '../../assets/img/photo.png';
import photoCharacter from '../../assets/img/photoCharacter.png';

const CharacterDetails = () => {
  return (
    <div className={styles.characterDetails}>
        <div className={styles.headerInfo}>
            <img src={photoTest}></img>
            <div className={styles.headerText}>
            <h2 className={styles.name}>Blade Reaper</h2>
            <p className={styles.subtitle}>Weapon Warrior</p>
            </div>
        </div>
        <div className={styles.infoWrapper}>
      <div className={styles.imageContainer}>
        <img
          src={photoCharacter}
          alt="Blade Reaper"
          className={styles.characterImage}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.stats}>
          <div className={styles.row}><p>Level</p><div><span>54</span></div></div>
          <div className={styles.row}><p>EXP</p><div><span> 7,300</span></div></div>
          <div className={styles.row}><p>HP</p><div><span>28 850</span></div></div>
          <div className={styles.row}><p>STR</p><div><span> 135 (+9)</span></div></div>
          <div className={styles.row}><p>DEF</p><div><span> 13,300</span></div></div>
          <div className={styles.row}><p>Abilities</p><div><img src={photoTest} className={styles.photoAb}></img></div></div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterDetails;
