import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../styles/Stream.css';

function Streams() {
  const { t } = useTranslation();
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStream, setActiveStream] = useState(null); // Для отслеживания активного стрима


  // useEffect(() => {
  //   async function fetchStreams() {
  //     try {
  //       const response = await axios.get('/streams'); // Здесь должен быть ваш URL
  //       setStreams(response.data); // Устанавливаем стримы в состояние
  //     } catch (err) {
  //       setError(t('error')); // Используем перевод ошибки
  //       console.error(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchStreams();
  // }, [t]);

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleStreamClick = (stream) => {
    if (activeStream === stream.username) {
      setActiveStream(null); // Закрыть стрим
    } else {
      setActiveStream(stream.username); // Открыть стрим
    }
  };

  return (
    <div>
      <h1>{t('streams')}</h1>
      {streams.length === 0 ? (
        <p>{t('noStreams')}</p>
      ) : (
        <div className="streams-container">
          {streams.map((stream, index) => (
            <div
              key={index}
              className={`stream-card ${activeStream === stream.username ? 'active' : ''}`}
              onClick={() => handleStreamClick(stream)}
            >
              <div className="stream-card-header">
                <h3>{stream.username} - {t('stream')}</h3>
              </div>
              <iframe
                src={stream.streamUrl}
                title={`${stream.username}'s Stream`}
                width={activeStream === stream.username ? '1000' : '200'} // Увеличиваем размер при открытии
                height={activeStream === stream.username ? '600' : '200'} // Увеличиваем размер при открытии
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Streams;
