import React from 'react';
import styles from './Header.module.css';
import bookImg from '../../assets/img/book-bookmark.png'
import search from '../../assets/img/search-01-solid-standard.png'
import users from '../../assets/svg/users.svg'
import bell from '../../assets/svg/bell.svg'

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.top}>
       <div className={styles.text}>
        <img src={bookImg}></img>
        WIKI
       </div>
      <div className={styles.resources}>
        <span className={styles.resource}>2,745 <span className={styles.small}>.13</span> <spam className={styles.violent}>SP</spam></span>
        <span className={styles.resource}>2,745 <span className={styles.small}>.13</span> <spam className={styles.blue}>EX</spam></span>
        <span className={styles.bage}>
        <img src={users}/>
        </span>
        <span className={styles.bage}>
            <img src={bell}/>
            <span className={styles.notification}>99</span>
        </span>
        <div className={styles.avatar}>
          <img src="/path-to-avatar.jpg" alt="Avatar" />
        </div>
      </div>
      </div>
      <div className={styles.bottom}>
        <img src={search}></img>
      <input
          type="text"
          placeholder="Search items and more"
          className={styles.searchInput}
        />
      </div> 
    </header>
  );
};

export default Header;
