// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Здесь мы определяем все переводы для разных языков
const resources = {
  en: {
    translation: {
      welcome: "Welcome to the homepage",
      streams: "Stream List",
      noStreams: "No active streams.",
      loading: "Loading...",
      error: "Error loading streams",
    },
  },
  ru: {
    translation: {
      welcome: "Добро пожаловать на главную страницу",
      streams: "Список стримов",
      noStreams: "Нет активных стримов.",
      loading: "Загрузка...",
      error: "Ошибка при загрузке стримов",
    },
  },
  // Добавить другие языки по мере необходимости
};

i18n
  .use(initReactI18next) // Подключаем React адаптер
  .init({
    resources,
    lng: 'en', // Начальный язык (можно сделать динамическим позже)
    keySeparator: false, // Разделитель для ключей переводов
    interpolation: {
      escapeValue: false, // Не нужно экранировать значения
    },
  });

export default i18n;
