import React, { useState } from "react";
import styles from "./RightSidebar.module.css";
import bannerRightMenu from "../../assets/img/bannerRightMenu.png"

const RightSidebar = () => {
  const [isSubmenuOpen, setSubmenuOpen] = useState(true);

  return (
    <div className={styles.rightSidebar}>
      {/* Картинка сверху */}
      <div className={styles.banner}>
        <img src={bannerRightMenu} alt="Banner" />
      </div>

      {/* Меню */}
      <div className={styles.menu}>
        <div className={styles.menuItem}>
          <span>Main Page</span>
        </div>
        <div className={styles.menuItem}>
          <span>About Game</span>
        </div>
        <div className={styles.menuItem}>
          <span>Items</span>
        </div>
        <div className={styles.menuItem}>
          <span>Locations</span>
        </div>
        <div className={styles.menuItem}>
          <span>Events</span>
        </div>
        <div className={styles.menuItem}>
          <span>Upgrade / Craft</span>
        </div>

        {/* Подменю "Creatures" */}
        <div
          className={`${styles.menuItem} ${styles.submenu}`}
          onClick={() => setSubmenuOpen(!isSubmenuOpen)}
        >
          <span>Creatures</span>
        </div>
        {isSubmenuOpen && (
          <div className={styles.submenuItems}>
            <div className={styles.submenuItem}>Pets</div>
            <div className={styles.submenuItem}>Mounts</div>
            <div className={styles.submenuItem}>Decorative Pets</div>
          </div>
        )}

        <div className={styles.menuItem}>
          <span>Favorites</span>
        </div>
        <div className={styles.menuItem}>
          <span>Other</span>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
