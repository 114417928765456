import React from "react";
import styles from "./InputField.module.css";

const InputField = ({ label, name, type = "text", value, onChange, error, icon, placeholder, children }) => {
  return (
    <div className={styles.inputField}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.inputWrapper}>
        {icon && <img className={styles.img} src={icon}></img>}
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className={error ? styles.errorInput : ""}
          placeholder={placeholder || ""}
        />
        {children}
      </div>
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default InputField;
