import React from 'react';
import Streams from '../../components/Stream.js';

function HomePage() {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Добро пожаловать на главную страницу</h1>
      <p>Здесь вы можете увидеть стримы от участников нашей гильдии на Discord.</p>
      <Streams />
    </div>
  );
}

export default HomePage;